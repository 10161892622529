import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { SidenavItem } from '../../sidenav/sidenav-item/sidenav-item.model';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../reducers/index';
import { fromEvent } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';

@Component({
	selector: 'elastic-search-bar',
	templateUrl: './search-bar.component.html',
	styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent implements OnInit {

	input: string;

	@ViewChild('inputElem') inputElem: ElementRef;
	focused: boolean;

	recentlyVisited: SidenavItem[] = [];
	frequentlyVisited: SidenavItem[] = [];
	sidenavItems: SidenavItem[] = [];
	searchResult: SidenavItem[] = [];

	constructor(
		private router: Router,
		private store: Store<fromRoot.State>,
		private cd: ChangeDetectorRef
	) {
	}

	ngOnInit() {
		this.store.select(fromRoot.getSidenavItems).pipe(
			filter(Boolean)
		).subscribe((items: SidenavItem[]) => {
			items.forEach(i => {
				if (i.visible) {
					this.sidenavItems.push(i);
	
					if (i.subItems.length) {
						this.sidenavItems.push(...i.subItems);
					}
				}
			});
			
			this.cd.markForCheck();
		});

		fromEvent(this.inputElem.nativeElement, 'keyup').pipe(
			distinctUntilChanged()
		).subscribe(() => {
			if (this.inputElem.nativeElement.value !== '') {
				this.searchResult = this.sidenavItems.filter(item => {
					return item.visible && item.name.toLowerCase().includes(this.inputElem.nativeElement.value);
				});

				this.cd.markForCheck();
			}
		});

		this.router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {

				const item = this.findByRouteRecursive(event.urlAfterRedirects);

				const index = this.recentlyVisited.indexOf(item);
				if (index > -1) {
					this.recentlyVisited.splice(index, 1);
				}

				this.recentlyVisited.unshift(item);

				if (this.recentlyVisited.length > 5) {
					this.recentlyVisited.pop();
				}

				this.cd.markForCheck();
			}

		});
	}


	findByRouteRecursive(route: string, collection: SidenavItem[] = this.sidenavItems): SidenavItem | null {
		let result = collection.find(item => item.route === route);

		if (!result) {
			collection.forEach(item => {
				if (item.hasSubItems()) {
					const found = this.findByRouteRecursive(route, item.subItems || []);

					if (found) {
						result = found;
						return false;
					}
				}
			});
		}

		return result;
	}

	openDropdown() {
		this.focused = true;
	}

	closeDropdown() {
		this.focused = false;
	}
}
