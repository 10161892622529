import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { FormControl, NgForm, Validators, FormGroup, FormArray } from '@angular/forms';

import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ROUTE_TRANSITION } from "../../../app.animation";

import { UsuarioAS400 } from '../../../core/models/usuario-as400.model';
import { UserService } from '../../../providers/user.service';

@Component({
	selector: "elastic-register",
	templateUrl: "./register.component.html",
	styleUrls: ["./register.component.scss"],
	animations: [...ROUTE_TRANSITION],
	// tslint:disable-next-line:use-host-property-decorator
	host: { "[@routeTransition]": "" }
})
export class RegisterComponent implements OnInit {

	form: FormGroup;

	user: any = {
		UEMAIL: '',
		UNOM: '',
		UPASS: '',
		UUSR: '',
		UVEND: '0'
	};

	passwordConfirm: string;

	condigoVendedorOptions: string[] = ['1001', '1002', '1003', '1010']; // Opciones de SSM

	filteredOptions: Observable<string[]>;

	constructor(
		private _router: Router,
		private _userService: UserService
	) {
		this.form = new FormGroup({
			UUSR: new FormControl('', Validators.required),
            UNOM: new FormControl('', Validators.required),
            UEMAIL: new FormControl('', [Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')]),
			UPASS: new FormControl('', Validators.required),
            'password-confirm': new FormControl(),
			UPRF400: new FormControl('', Validators.required),
			UVEND: new FormControl('0'),
		});
		
		this.form.get('password-confirm').setValidators([
            Validators.required,
            this.noEqual.bind(this)
		]);

		this.form.setValue(Object.assign({ 'password-confirm': '' }, this.user));
	}

	ngOnInit() {

		this.filteredOptions = this.form.get('UVEND').valueChanges
			.pipe(
				startWith(''),
				map(value => this._filter(value ? value.toString() : '')),
			);
	}

	/**
	 * Filtra las opciones del autocomplete
	 */
	private _filter(value: string): string[] {
		return this.condigoVendedorOptions.filter(
			option => !value || option.toLowerCase().includes(value.toLowerCase())
		);
	}

	noEqual(control: FormControl): { [s: string]: boolean } {
        if (control.value !== this.form.get('UPASS').value) {
            return {
                'no-equal': true
            };
        }
        else {
            return null;
        }
    }

	register() {
		console.log('form (group) :', this.form);
		// this._userService.registerUser(this.form.value).subscribe(response => {
		// 	if (response.status === 200) {
		// 		console.info(`user ${this.form.value.UUSR} registered`);
		// 		this._router.navigate(['/auth/login'], { queryParams: { user: this.form.value.UUSR } });
		// 	}
		// });
	}
}
