import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthService } from '../auth.service';
import { StatsService } from '../stats.service';
import { ModuleService } from '../module.service';
import { Utils } from '../../core/utils/index';

@Injectable({ providedIn: 'root' })
export class StatsGuard implements CanActivate {
    constructor(
        private _router: Router,
        private _authService: AuthService,
        private _statsService: StatsService,
        private _moduleService: ModuleService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentUser = this._authService.currentUserValue;

        if (currentUser) {
            this._statsService.getClientIpAddress().then((clientIp) => {
                let menuOption = this._moduleService.findMenuOptionFromUserAssignments(state.url);
    
                let wd = Utils.getWindowDimensions();
                let stat = {
                    AAPP: "BDIGITAL",
                    AMOD: menuOption ? menuOption.AMOD : "Portal",
                    ADESC: menuOption ? menuOption.ATIT : "",
                    AURL: state.url,
                    AUSR: currentUser.Usuario,
                    AMOBILE: Utils.isMobile() ? 1 : 0,
                    AWIDTH: wd[0],
                    AHEIGHT: wd[1],
                    ANAVIGATOR: Utils.getUserAgent(),
                    AIPADDR: clientIp || ""
                };
    
                this._statsService.create(stat).toPromise();
            });
        }


        return true;
    }
}