import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SupportService } from 'app/providers/support.service';
import { SystemService } from '../../../providers/system.service';

@Component({
	selector: 'elastic-toolbar-gamma',
	templateUrl: './toolbar-gamma.component.html',
	styleUrls: ['./toolbar-gamma.component.scss']
})
export class ToolbarGammaComponent implements OnInit {

	@Input() quickpanelOpen: boolean;

	@Output() toggledSidenav = new EventEmitter();
	@Output() toggledQuickpanel = new EventEmitter();

	constructor(
		public systemService: SystemService,
		private _supportService: SupportService
	) { }

	ngOnInit() { }

	toggleSidenav() {
		this.toggledSidenav.emit();
	}

	toggleQuickpanel() {
		this.toggledQuickpanel.emit();
	}

	openFreshdesk() {
		this._supportService.openFreshdesk();
	}

	openTutorialsDoc() {
		this._supportService.openTutorialsDoc();
	}
}
