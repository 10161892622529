import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DisablePasteDirective } from './disable-paste.directive';
import { OnlyNumbersDirective } from './onlyNumbers.directive';
import { MinMaxNumberDirective } from './min-max-number.directive';
import { TrackCapsDirective } from './track-caps.directive';
import { AutocompleteOffDirective } from './autocomplete-off.directive';


@NgModule({
    declarations: [
        DisablePasteDirective,
        OnlyNumbersDirective,
        MinMaxNumberDirective,
        TrackCapsDirective,
        AutocompleteOffDirective
    ],
    imports: [ CommonModule ],
    exports: [
        DisablePasteDirective,
        OnlyNumbersDirective,
        MinMaxNumberDirective,
        TrackCapsDirective,
        AutocompleteOffDirective
    ],
    providers: [],
})
export class DirectivesModule {}