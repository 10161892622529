import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NotificationsCountComponent } from './notifications-count.component';

@NgModule({
    declarations: [
        NotificationsCountComponent
    ],
    imports: [ 
        CommonModule
    ],
    exports: [
        NotificationsCountComponent
    ],
    providers: [],
})
export class NotificationsCountModule {}