import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '../../../providers/auth.service';
import { SystemService } from 'app/providers/system.service';
import { UserService } from '../../../providers/user.service';
import { ToastService } from '../../../providers/toast.service';

@Component({
	selector: 'elastic-toolbar-user-button',
	templateUrl: './toolbar-user-button.component.html',
	styleUrls: ['./toolbar-user-button.component.scss']
})
export class ToolbarUserButtonComponent implements OnInit, AfterViewInit {

	isOpen: boolean;

	infoUsuario: any = {};

	userVendors: any[] = [];

	systemInfo = {
		datasource: '',
		library: '',
		version: ''
	};

	selectedVendor: any;

	refreshing: boolean;

	constructor(
		private _auth: AuthService,
		private _systemService: SystemService,
		private _userService: UserService,
		private _router: Router,
		private _toast: ToastService
	) { 
		this.getSystemInfo();
	}

	ngOnInit() {
		this._auth.currentUser.subscribe((userInfo) => {
			this.infoUsuario = userInfo;

			if (this.infoUsuario && this.infoUsuario.Vendedor)
				this.selectedVendor = this.infoUsuario.Vendedor;
		});

		this._userService.userVendors.subscribe(vendors => {
			this.userVendors = vendors;
		});
	}

	ngAfterViewInit() {
	}

	toggleDropdown() {
		this.isOpen = !this.isOpen;
	}

	onClickOutside() {
		this.isOpen = false;
	}

	logout() {
		this._auth.logout();
		this._router.navigate(['/auth/login']);
	}

	async getSystemInfo() {
		this.systemInfo = await this._systemService.getSystemInfo().toPromise();
	}

	changeVendor() {
		console.info("changing vendor");
		this._auth.changeUserVendor(this.selectedVendor);

		setTimeout(() => {
			location.reload();
		});
	}

	async refreshProfile() {
		if (this.refreshing)
			return;
			
		this.refreshing = true;
		try {
			await this._auth.refreshProfile();
			location.reload();
		} catch (err) {
			const errMsg = err.error ? err.error.ExceptionMessage || err.Message || err.ExceptionMessage : (err || "Error al actualizar el perfíl");
			this._toast.error(errMsg);	
		} finally {
			this.refreshing = false;
		}
	}
}
