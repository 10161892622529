import { Directive, ElementRef, Input, HostListener } from '@angular/core';

@Directive({
    selector: '[onlyNumbers]',
})
export class OnlyNumbersDirective {
    constructor() { }

    @HostListener('input', ['$event.target']) onInput(e) {
        e.value = e.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
    }
}