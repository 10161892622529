import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';

@Injectable({
	providedIn: 'root'
})
export class StatsService extends BaseService {

	modelName = 'stats';

	constructor(
		private _http: HttpClient
	) {
		super(_http);

		this.model = (construct: any) => {
			return Object.assign({}, construct);
		};
	}

	/**
	 * Obtiene la dirección IP del usuario
	 */
	async getClientIpAddress() {
		let url = `https://www.cloudflare.com/cdn-cgi/trace`;
		let data = (await this._http.get(url, { responseType: 'text' }).toPromise() as string);
		let ip = data.split("\n").find(d => d.startsWith("ip"));

		if (ip) {
			ip = ip.replace("ip=", "");
		}

		return ip;
	}
}
