import { Directive, ElementRef, Input, HostListener } from '@angular/core';

@Directive({
    selector: '[minMaxNumber]',
})
export class MinMaxNumberDirective {

    private _minMax: number[];

    public get minMax(): number[] {
        return this._minMax;
    }
    
    @Input('minMaxNumber')
    public set minMax(v: number[]) {
        this._minMax = v;
    } 

    constructor() { }

    @HostListener('input', ['$event.target']) onInput(e) {
        if (this.minMax && this.minMax.length) {
            let value = parseFloat(e.value);

            if (value < this.minMax[0])
                e.value = this.minMax[0];
            else if (this.minMax[1] && value > this.minMax[1])
                e.value = this.minMax[1];
        }
    }
}