import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MatSnackBar } from '@angular/material';

import { ROUTE_TRANSITION } from '../../../app.animation';
import { AuthService } from '../../../providers/auth.service';
import { ToastService } from 'app/providers/toast.service';
import { PromptDialogComponent } from 'app/components/prompt-dialog/prompt-dialog.component';
import { takeUntil } from 'rxjs/operators';
import { UserService } from '../../../providers/user.service';

@Component({
	selector: 'elastic-forgot-password',
	templateUrl: './forgot-password.component.html',
	styleUrls: ['./forgot-password.component.scss'],
	animations: [...ROUTE_TRANSITION],
	// tslint:disable-next-line:use-host-property-decorator
	host: { '[@routeTransition]': '' }
})
export class ForgotPasswordComponent implements OnInit {

	username: string;

	loading: boolean;

	errorMsg: string;

	forLX: boolean;

	constructor(
		private _router: Router,
		private _authService: AuthService,
		private _userService: UserService,
		private _toast: ToastService,
		public dialog: MatDialog,
	) { }

	ngOnInit() {
	}

	async send() {
		this.loading = true;
		try {
			this.forLX = await this._userService.checkUPRF400(this.username).toPromise();

			this._authService.requestPassRecovery(this.username).subscribe(response => {
				this.showInfoPrompt();
			}, (error) => {
				let err = error.error.ExceptionMessage || error.Message;
				this.errorMsg = err.split(' | ')[0];
				if (this.errorMsg.includes('Se han encontrado dos o mas usuarios'))
					this.errorMsg += '. Comuníquese con el equipo de tecnología';
					
				this.loading = false; 
			}, () => { 
				this.loading = false; 
			});
		} catch (error) {
			let err = error.error.ExceptionMessage || error.Message;
			this.errorMsg = err.split(' | ')[0];
			this.loading = false;
		}
	}

	showInfoPrompt() {
		const dialogRef = this.dialog.open(PromptDialogComponent, {
			minWidth: "320px",
			data: {
				title: `Recuperación de Contraseña`,
				message: `Hemos enviado a su correo un código de verificación para la recuperación de su contraseña. 

				Por favor revise su correo y verifique las carpetas de Spam y Papelera en caso de no encontrar el código en su bandeja principal`,
				action: async () => {
					// this._router.navigate(['/auth/pass-recovery'], { queryParams: { user: this.username } });
				},
				ok: "Entendido",
				cancel: ""
			}
		});

		dialogRef.afterClosed().subscribe(result => {
			this._router.navigate(['/auth/pass-recovery'], { queryParams: { user: this.username, lx: this.forLX } });

			// if (result) {
			// 	this._toast.info("Revise su correo para continuar con el proceso de recuperación de contraseña");
			// }
		});
	}
}
