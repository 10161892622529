import { Component, Input, OnInit, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { SidenavItem } from './sidenav-item.model';
import * as fromRoot from '../../../reducers/index';
import * as sidenavAction from '../shared/sidenav.action';
import { Store } from '@ngrx/store';

@Component({
	selector: 'elastic-sidenav-item',
	templateUrl: './sidenav-item.component.html',
	styleUrls: ['./sidenav-item.component.scss']
})
export class SidenavItemComponent implements OnInit {

	scrollbar: any;

	// tslint:disable-next-line:no-input-rename
	@Input('item') item: SidenavItem;
	// tslint:disable-next-line:no-input-rename
	@Input('currentlyOpen') currentlyOpen: SidenavItem[] = [];

	forcedInitFlag = false;

	constructor(
		private store: Store<fromRoot.State>,
		private _cd: ChangeDetectorRef
	) { }

	ngOnInit() {
	}

	toggleDropdown() {
		if (this.item.hasSubItems()) {
			this.store.dispatch(new sidenavAction.ToggleOpenSidenavItemAction(this.item));

			// this.forcedInitFlag = !this.forcedInitFlag;
			// this._cd.detectChanges();
			// console.log('this.forcedInitFlag :', this.forcedInitFlag);
		}
	}

	isOpen(item: SidenavItem) {
		return this.currentlyOpen.indexOf(item) > -1;
	}

	// Receives the count of Sub Items and multiplies it with 48 (height of one SidenavItem) to set the height for animation.
	getSubMenuHeight(): string {
		return (this.getSubMenuItemsCount(this.item) * 48) + 'px';
	}

	// Counts the amount of Sub Items there is and returns the count.
	getSubMenuItemsCount(item: SidenavItem): number {
		let count = 0;

		if (item.hasSubItems() && this.isOpen(item)) {
			count += item.subItems.length;

			item.subItems.forEach((subItem) => {
				count += this.getSubMenuItemsCount(subItem);
			});
		}

		return count;
	}
}
