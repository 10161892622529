import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'app/providers/auth.service';


@Component({
  selector: 'app-login-token',
  templateUrl: './login-token.component.html',
  styleUrls: ['./login-token.component.scss']
})
export class LoginTokenComponent implements OnInit {

  id: string;

  constructor( 
    private route: ActivatedRoute,
    private authService:AuthService,
    ) { }

  ngOnInit() {
    // this.id = this.route.snapshot.paramMap.get('token');
    // console.log('este es el token', this.id);

    const url = this.route.queryParams.subscribe(params => {
      this.id = params['t'];
      console.log(this.id);

      this.authService.loginToken(this.id);

    });

    //  const prueba = this.authService.loginToken(this.id).toPromise();

  }

}
