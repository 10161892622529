import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { delay, map } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';

import { APP_CONFIG } from 'app/config';
import { BaseService } from './base.service';
import { UsuarioAS400 } from 'app/core/models/usuario-as400.model';

@Injectable({
	providedIn: 'root'
})
export class UserService extends BaseService {

    modelName = 'users';
    
    private userVendorsSubject: BehaviorSubject<any>;
	
    public userVendors: Observable<any>;
    
    public get userVendorsValue(): any {
        return this.userVendorsSubject.value;
    }

	constructor(
		private _http: HttpClient
	) {
		super(_http);

		this.model = (construct: any) => {
			return construct;
        };
        
        const localVendors = localStorage.getItem('user-vendors');

        if (localVendors)
            this.userVendorsSubject = new BehaviorSubject<any>(JSON.parse(localVendors));
        else {
            this.userVendorsSubject = new BehaviorSubject<any>([]);
        }

		this.userVendors = this.userVendorsSubject.asObservable();
    }
    
    usernameInUse(username: string): Observable<boolean> {
        return this.findById(username).pipe(delay(666), map(user => {
            return user !== null;
        }));
    }

    findAll() {
        return this.find({ all: true });
    }

    findBySellerCode(code: number) {
        return this.findById(code, { prefix: 'by-seller-code' });
    }

    /**
     * Activa/inactiva un usuario
     * @param username Usuario
     * @param actualState Estado actual del usuario
     */
    setUserState(username: string, actualState: string) {
        if (actualState === 'A') {
            return this._http.put(`${this.url}/${this.modelName}/restore/${username}`, null).pipe(map(() => 'A'));
        }
        else {
            return this.delete(username).pipe(map(() => 'Z'));
        }
    }

    generateReport() {
        window.open(`${this.url}/${this.modelName}/report`, '_self');
        // return this._http.get(`${this.url}/${this.modelName}/report`);
    }

    async getUserVendors(username: string) {
        let vendors = await this.http.get(`${this.url}/${this.modelName}/vendors/${username}`).toPromise();
        this.userVendorsSubject.next(vendors);
    }
    
    getUserInfoByJWT(username: string, jwt: string) {
        return this.http.get(`${this.url}/${this.modelName}/${username}?token=${jwt}&app=ENLACEBRINSA`);
    }

    checkUPRF400(username: string) {
        return this.http.get<boolean>(`${this.url}/${this.modelName}/checkuprf400?username=${username}`);
    }

}
