import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RecoveryPasswordComponent } from './recovery-password.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material';
import { DirectivesModule } from '../../../directives/directives.module';
import {
	MatButtonModule,
	MatCheckboxModule,
	MatInputModule,
	MatSnackBarModule
} from "@angular/material";

@NgModule({
	imports: [
		CommonModule,
		RouterModule,
		DirectivesModule,
		FormsModule,
		ReactiveFormsModule,
		FlexLayoutModule,
		MatInputModule,
		MatButtonModule,
		MatCheckboxModule,
		MatSnackBarModule,
		MatIconModule
	],
	declarations: [RecoveryPasswordComponent]
})
export class RecoveryPasswordModule { }
