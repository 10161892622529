import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'app/providers/auth.service';

@Component({
  selector: 'app-redirecionar',
  templateUrl: './redirecionar.component.html',
  styleUrls: ['./redirecionar.component.scss']
})
export class RedirecionarComponent implements OnInit {


  constructor(private route:ActivatedRoute, private _authService:AuthService,) { }

  ngOnInit() {
   this.route.queryParams.subscribe(params => {
    const urlbase64 = atob(params['url']);
    const nuevaUrl = `${urlbase64}?token=${this._authService.getIdentityToken()}&menu=false`; 
    window.location.href = nuevaUrl;
    
   })
  }

}
