import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AuthModule } from "./auth/auth.module";
import { UpdatesComponent } from './precios-proveedor/updates/updates.component';

@NgModule({
    imports: [CommonModule, AuthModule],
    declarations: [UpdatesComponent]
})
export class PagesModule {}
