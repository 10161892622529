import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'textToHtml'
})
export class TextToHtmlPipe implements PipeTransform {

	transform(value: any, args?: any): any {
		return (value as any).toString()
				.replace(/<[^>]+>/g, '')
				.replace('SafeValue must use [property]=binding:', '')
				.replace('(see http://g.co/ng/security#xss)', '')
				.replace('undefined', '');
	}

}
