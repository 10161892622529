import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { SidenavItem } from './sidenav-item/sidenav-item.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as fromRoot from '../../reducers/index';
import * as fromSidenav from "../sidenav/shared/sidenav.action";
import { Store } from '@ngrx/store';
import { AuthService } from 'app/providers/auth.service';
import { Module } from '../models/module.model';
import { MenuOption } from '../models/menu-option.model';
import { ModuleService } from 'app/providers/module.service';
import { ProcessService } from '../../providers/process.service';
import { SystemService } from '../../providers/system.service';

@Component({
	selector: 'elastic-sidenav',
	templateUrl: './sidenav.component.html',
	styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit, OnDestroy {

	// tslint:disable-next-line:no-input-rename
	@Input('layout') layout: string; // Layout
	// tslint:disable-next-line:no-input-rename
	@Input('collapsed') collapsed: boolean;
	// tslint:disable-next-line:no-output-rename
	@Output('toggledSidenavCollapse') toggledSidenavCollapse = new EventEmitter();

	sidenavItems$: Observable<SidenavItem[]>;

	currentlyOpen$: Observable<SidenavItem[]>;

	dynamicItemsRef: SidenavItem[] = [];

	user: any = {};

	constructor(
		private store: Store<fromRoot.State>,
		private _authService: AuthService,
		private _moduleService: ModuleService,
		private _processService: ProcessService,
		public systemService: SystemService
	) {
		
	}

	ngOnInit() {
		// Selecciona sólo los items visibles
		this.sidenavItems$ = this.store.select(fromRoot.getSidenavItems).pipe(map(items => {
			return items.filter(i => i.visible);
		}));

		this.currentlyOpen$ = this.store.select(fromRoot.getSidenavCurrentlyOpen);
		
		this.user = this._authService.currentUserValue;

		if (this.user) {
			let userModules: Module[] = this.user['Asignaciones'] ? this._moduleService.groupModulesMenuOptions(this.user['Asignaciones']) : [];

			userModules.forEach((um, index) => {
				let menuItem: SidenavItem = null;

				if (um.RCAM && um.RCAM.length === 1 && um.ADESC === um.RCAM[0].ATIT) {
					menuItem = new SidenavItem({
						name: um.RCAM[0].ATIT,
						route: `/${um.RCAM[0].ALINK}`,
						subItems: [],
						position: 1,
						icon: um.RCAM[0].AICONO || um.AICONO
					});

					if (um.RCAM[0].ATIT === 'Aprobaciones')
						menuItem.badge = this._processService.getPendingProcessesCount(this.user['Usuario']);
				}
				else {
					menuItem = new SidenavItem({
						name: um.ADESC,
						subItems: [],
						position: 1,
						icon: um.AICONO
					});

					this.mapSidenavItems(um.RCAM, menuItem);

				}
				
				this.dynamicItemsRef.push(menuItem);
				this.store.dispatch(new fromSidenav.AddSidenavItemAction(menuItem));

				// Si es el primer elemento, es el módulo por defecto
				// if (index === 0)
				// 	this.store.dispatch(new fromSidenav.ToggleOpenSidenavItemAction(menuItem));
			});
		}
	}

	ngOnDestroy() {
		// TODO: Eliminar las opciones de menú cargadas dinámicamente cuando el menú lateral (Sidenav) no está presente
		this.dynamicItemsRef.forEach(item => {
			this.store.dispatch(new fromSidenav.RemoveSidenavItemAction(item));
		});
	}

	/**
	 * Crea items para el sidenav desde una lista de opciones de menú
	 */
	mapSidenavItems(menuOptions: MenuOption[] = [], parent: SidenavItem = null) {
		menuOptions.forEach(option => {
			let menuItem = new SidenavItem({
				name: option.ATIT,
				route: `/${option.ALINK}`,
				parent: parent,
				subItems: [],
				position: 1,
				icon: option.AICONO
			});

			
			if (option.RCAM && option.RCAM.length) {
				this.mapSidenavItems(option.RCAM, menuItem);
			}

			if (parent)
				parent.subItems.push(menuItem);
		});

		return true;
	}

	toggleSidenavCollapse() {
		this.toggledSidenavCollapse.emit();
	}
}
