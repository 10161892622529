import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of, Observable } from 'rxjs';
import * as _ from 'lodash';

import { BaseService } from './base.service';
import { APP_CONFIG } from 'app/config';
import { Module } from '../core/models/module.model';
import { MenuOption } from '../core/models/menu-option.model';
import { UserModuleOption } from '../core/models/user-module-option.model';
import { map, filter } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable({
	providedIn: 'root'
})
export class ModuleService extends BaseService {

	model: any;

	modelName = 'modules';

	constructor(
        private _http: HttpClient,
        private _authService: AuthService
	) { 
		super(_http);
	}

	groupModulesMenuOptions(modules: Module[]) {
		return modules.map((mod) => {
            if (!mod['RCAM']) {
                mod['RCAM'] = [];
            }

            let options = (mod['RCAM'] as Array<MenuOption>).sort((a, b) => a.AORD - b.AORD);
            let optionsDict = {};
            let reasignedOptions = [];
            
            // Construye un diccionario con las opciones de menú padre
            options.forEach(opt => {
                optionsDict[opt['AMENU']] = opt;
            });


            // Agrega las opciones de menú hijo a su respectiva opción padre (en el diccionario)
            options.forEach(opt => {
                if (opt['APARENT'].toString() !== opt['AMENU'].toString()) {
                    if (optionsDict[opt['APARENT']]) {
                        if (!(optionsDict[opt['APARENT']]['RCAM'] && optionsDict[opt['APARENT']]['RCAM'].length)) {
                            optionsDict[opt['APARENT']]['RCAM'] = [];
                        }

                        optionsDict[opt['APARENT']]['RCAM'].push(opt);
                        reasignedOptions.push(opt['AMENU']);
                    }
                }
            });

            // Como ya se han agregado las opciones hijo a sus respectivos padres
            // se eliminan del diccionario para evitar duplicados
            reasignedOptions.forEach(opt => {
                delete optionsDict[opt];
            });
            
            mod['RCAM'] = _.values(optionsDict);
            return mod;
        });
    }
    

    findMenuOptionFromUserAssignments(url: string) {
        if (url.startsWith("/"))
            url = url.replace("/", "");

        let assignments = (<Array<any>>this._authService.currentUserValue['Asignaciones']);
        console.log('assignments :', assignments);

        let findMenuOptions = (mod: Module) => {
            if (mod['RCAM'] && mod['RCAM'].length) {
                let match = (mod['RCAM'] as Array<any>).find(om => om.ALINK === url);

                if (match)
                    return match;
                else {
                    (mod['RCAM'] as Array<any>).forEach(om => {
                        if (!match)
                            match = findMenuOptions(om);
                    });

                    return match;
                }
            }
            else
                return null;
        };

        let finalMatch = null;

        assignments.forEach(a => {
            if (!finalMatch)
                finalMatch = findMenuOptions(a);
        });

        return finalMatch;
    }

    getUserMenuOptionById(id: number) {
        let assignments = (<Array<any>>this._authService.currentUserValue['Asignaciones']);
        
        let findMenuOptions = (mod: Module) => {
            if (mod['RCAM'] && mod['RCAM'].length) {
                let match = (mod['RCAM'] as Array<any>).find(a => a['AMENU'] === id);

                if (match)
                    return match;
                else {
                    (mod['RCAM'] as Array<any>).forEach(om => {
                        if (!match)
                            match = findMenuOptions(om);
                    });

                    return match;
                }
            }
            else
                return false;
        };

        let finalMatch = null;

        assignments.forEach(a => {
            if (!finalMatch)
                finalMatch = findMenuOptions(a);
        });

        return finalMatch;
    }

    getUserModulePermissions(module: string) {
        let user = this._authService.currentUserValue;
        let permissions = null;

        if (user['Asignaciones'] && user['Asignaciones'].length) {
            permissions = user['Asignaciones'].filter(a => a['AMOD'] === module).map(a => a['RCAA'][0]);
            permissions = permissions ? permissions[0] : null;
        }

        return permissions;
    }
}
