import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { QuickpanelComponent } from './quickpanel.component';
import { MatButtonModule, MatListModule, MatProgressBarModule, MatTabsModule, MatIconModule } from '@angular/material';
import { ScrollbarModule } from '../scrollbar/scrollbar.module';
import { PipesModule } from '../../pipes/pipes.module';
import { RouterModule } from '@angular/router';

@NgModule({
	imports: [
		CommonModule,
		RouterModule,
		FlexLayoutModule,
		PipesModule,
		MatButtonModule,
		MatProgressBarModule,
		MatTabsModule,
		MatListModule,
		MatIconModule,
		ScrollbarModule
	],
	declarations: [QuickpanelComponent],
	exports: [QuickpanelComponent]
})
export class QuickpanelModule { }
