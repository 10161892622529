import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';

@Component({
	selector: 'app-prompt-dialog',
	templateUrl: './prompt-dialog.component.html',
	styles: [``]
})
export class PromptDialogComponent implements OnInit {

	config: any = {};

	doingSomething: boolean;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		private snackBar: MatSnackBar,
		public dialogRef: MatDialogRef<PromptDialogComponent>,
	) {
		this.config = data;
	}

	ngOnInit() {
	}

	/**
	 * Cierra el diálogo modal
	 */
	onNoClick(): void {
		this.dialogRef.close();
	}

	ok() {
		if (this.config['action']) {
			this.doingSomething = true;
			
			(this.config['action'] as Function)().then((result) => {
				this.dialogRef.close(true);
			}).catch((err) => {
				this.snackBar.open(err.error.ExceptionMessage || err.ExceptionMessage, "Cerrar", {
					duration: 0
				});
			}).finally(() => {
				this.doingSomething = false;
			});
		}
		else {
			this.dialogRef.close();
		}
	}
}
