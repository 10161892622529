import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthService } from '../auth.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private _router: Router,
        private _authService: AuthService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentUser = this._authService.currentUserValue;
        if (currentUser) {
            // Usuario logueado
            console.log('currentUser :', currentUser);
            return true;
        }
        else {
            // No hay usuario logueado, redireccionar a la página de login con la ruta de retorno
            this._router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url } });
            return false;
        }
    }
}