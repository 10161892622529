import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class UIConfigService {

	private uiConfigSubject: BehaviorSubject<any>;

	public uiConfig: Observable<any>;

	constructor() { 
		this.uiConfigSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('ui-config')));
		this.uiConfig = this.uiConfigSubject.asObservable();
	}

	public get uiConfigValue(): any {
        return this.uiConfigSubject.value;
	}
	
	/**
	 * Guarda en el local storage el estado de las variables de configuración de la interfaz de usuario
	 */
	private _setUiConfig(config: any) {
		localStorage.setItem('ui-config', JSON.stringify(config));
		this.uiConfigSubject.next(config);
	}

	/**
	 * Guarda el modo de layout en las opciones de la interfaz de usuario
	 */
	setLayoutMode(mode: any) {
		let config = this.uiConfigValue || {};
		config['layoutMode'] = mode;
		this._setUiConfig(config);
	}
}
