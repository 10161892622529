import { Directive, ElementRef, Input, HostListener } from '@angular/core';

@Directive({
    selector: '[autocompleteOff]',
})
export class AutocompleteOffDirective {
    constructor() { }

    @HostListener('focus', ['$event.target']) onInput(e) {
        e.autocomplete = "off";
    }
}